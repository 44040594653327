import styled, { css } from 'styled-components';

import { getCSSFromStyleProps } from '@dop/ui-primitives/base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '@dop/ui-primitives/base/StyleProps.types';
import {
	baseStaticStyleMap,
	BaseStaticStyleProps,
} from '@dop/ui-primitives/base/Base';
import {
	getPaddingBlockCSS,
	getPaddingCSS,
	getPaddingInlineCSS,
} from '@dop/ui-primitives/planeDivision/planeDivisionStyleFunctions';
import {
	Padding,
	PaddingBlock,
	PaddingInline,
} from '@dop/ui-primitives/planeDivision/planeDivisionDefinitions';
import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

/**
 * Temporary helper-component to position elements from the Design-system in non-design system containers
 * The padding prop is taken from the design-system, i.e. it can be a negative value to allow for negative margins.
 * When the entire site is moved over to the design-system (with stacks/shelves), this component is no longer needed.
 *
 * Responsibilities:
 *
 * 1. Allow for padding to position internal design-system elements
 *
 * @param props.$padding padding in --root-cap (a number, or array of 2 or 4 numbers)
 */

type WrapperStaticStyleProps = BaseStaticStyleProps & {
	$padding?: Padding;
	$paddingInline?: PaddingInline;
	$paddingBlock?: PaddingBlock;
};

type WrapperComponentStyleProps = ComponentStyleProps<WrapperStaticStyleProps>;

const wrapperStaticStyleMap: StyleMap<WrapperStaticStyleProps> = {
	...baseStaticStyleMap,
	$padding: getPaddingCSS,
	$paddingInline: getPaddingInlineCSS,
	$paddingBlock: getPaddingBlockCSS,
};

const wrapperStyleMap: ComponentStyleMap<WrapperStaticStyleProps> = {
	normal: wrapperStaticStyleMap,
};

export const DesignSystemWrapper = styled.span<WrapperComponentStyleProps>`
	${(props) => css`
		display: block;
		${getCSSFromStyleProps(wrapperStyleMap, props)}
	`}
`;

export type DesignSystemWrapperProps = StyledComponentPropsWithoutRef<
	typeof DesignSystemWrapper
>;
