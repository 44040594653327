import styled, { css } from 'styled-components';

import theme from '../styles/theme';
import { hemelblauw } from '../styles/colors';

export const CrsWrapper = styled.div<{
	$paddingBottom?: boolean;
	$sharedPaddingBottom?: boolean;
}>`
	flex: none;
	padding-top: ${theme.halfSpace};
	background-color: ${hemelblauw.lightest};
	position: relative;

	${(props) =>
		props.$paddingBottom &&
		css`
			padding-bottom: 3rem;
		`};

	${(props) =>
		props.$sharedPaddingBottom
			? css`
					padding-bottom: ${theme.halfSpace};
					&:after {
						content: '';
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 5rem;
						background-color: ${hemelblauw.lightest};
					}
			  `
			: ''};
`;
