import { SvgContent } from '@dop/ui-primitives/media/Svg';
import { translate } from '@dop/shared/translate/translate';

export const IconSmartphone: SvgContent = ({ cropped = false, ...props }) => (
	<svg
		{...(cropped
			? { viewBox: '13 0 37.8 64', width: '37.8', height: '64' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		role="img"
		aria-hidden
		{...props}
	>
		<title>{translate('phoneNumber')}</title>
		<g>
			<rect fill="none" width="64" height="64" />
		</g>
		<g>
			<g>
				<path d="M46.493572,1.067328 C36.815008,-0.355776 27.07987,-0.355776 17.401306,1.067328 C14.911042,1.443392 13,3.795904 13,6.183872 L13,57.816 C13.000126,60.204096 15.006046,62.556352 17.49631,62.932672 C27.174874,64.355776 36.815008,64.355776 46.493572,62.932672 C48.984088,62.55648 50.8,60.20384 50.8,57.816 L50.8,6.183872 C50.8,3.795904 48.984088,1.443392 46.493572,1.067328 Z M31.9,60.351936 C30.055864,60.351936 28.561,58.833344 28.561,56.959936 C28.561,55.086528 30.055864,53.567936 31.9,53.567936 C33.744136,53.567936 35.239,55.086528 35.239,56.959936 C35.239,58.833344 33.744136,60.351936 31.9,60.351936 Z M45.76,49.919936 L18.04,49.919936 L18.04,8.959936 L45.76,8.959936 L45.76,49.919936 Z" />
			</g>
		</g>
	</svg>
);
