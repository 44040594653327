import React from 'react';

import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { IconLink } from '@dop/ui-icons/svg/link';
import {
	ShelfLinkBlock,
	ShelfLinkIcon,
	ShelfLinkText,
} from '@dop/ui-composites/link/ShelfLink';
import { Occupy } from '@dop/ui-primitives/layout/Occupy';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { LinkType } from '@/globals/webApiTypes/common.types';

export const ContactBlockLink = ({ href, text }: LinkType) => {
	if (!href) return null;

	return (
		<Box $padding={[1.5, 0]}>
			<ShelfLinkBlock
				href={href}
				linkType="contactBlockLink"
				{...addTestSelector('uiContactBlockUrl')}
			>
				<Occupy
					$blockSize={1}
					$inlineSize={2}
					$justifyItems={'start'}
					$alignSelf={'center'}
				>
					<ShelfLinkIcon
						as={IconLink}
						$blockSize={2}
						$fill={['text', 'primary']}
					/>
				</Occupy>
				<ShelfLinkText
					$color={['text', 'primary']}
					{...addTestSelector('uiContactBlockText')}
				>
					{text}
				</ShelfLinkText>
			</ShelfLinkBlock>
		</Box>
	);
};
