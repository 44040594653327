import { IconSmartphone } from '@dop/ui-icons/svg/smartphone';
import { Occupy } from '@dop/ui-primitives/layout/Occupy';
import {
	ShelfLinkBlock,
	ShelfLinkIcon,
	ShelfLinkText,
} from '@dop/ui-composites/link/ShelfLink';
import { TextInline } from '@dop/ui-primitives/typography/TextInline';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { Stack } from '@dop/ui-primitives/layout/Stack';

export const ContactBlockPhone = ({
	organisation,
	phoneNumber,
	clarification,
}: {
	organisation?: string;
	phoneNumber: string;
	clarification?: string;
}) => {
	if (!phoneNumber) return null;

	return (
		<Box $padding={[1.5, 0]}>
			<ShelfLinkBlock
				href={`tel:${phoneNumber}`}
				linkType="contactBlockPhoneLink"
			>
				<Occupy
					$blockSize={1}
					$inlineSize={2}
					$justifyItems={'start'}
					$alignSelf={'center'}
				>
					<ShelfLinkIcon
						as={IconSmartphone}
						$blockSize={2}
						$fill={['text', 'primary']}
					/>
				</Occupy>
				<Stack $gap={1}>
					<ShelfLinkText $color={['text', 'primary']}>
						{organisation != null && (
							<TextInline>{organisation.trim() + ' '}</TextInline>
						)}
						<TextInline
							$fontWeight="bold"
							$fontVariantNumeric="lining-nums"
							$display="inline-block"
						>
							{phoneNumber}
						</TextInline>
					</ShelfLinkText>
					{clarification != null && (
						<TextBlock $capSize={[1, 1.8]} $color={['text', 'muted']}>
							{clarification}
						</TextBlock>
					)}
				</Stack>
			</ShelfLinkBlock>
		</Box>
	);
};
