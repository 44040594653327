import { css } from 'styled-components';

/**
 * This is a bit of CSS that set the up the now-deprecated LayoutGridContainer, which used flex for placement
 * and some margins on it's contents. A lot of components (like <PromoBlock>s) still use these settings
 * (for example with margins to offset the negative margins set by LayoutGridContainer).
 * Usage:
 *
 * <MainPageLayout>
 *      <Box $gridArea="main" css={deprecatedFlexLayoutCss}>
 *      ... // content that depends on old LayoutGridContainer-styling
 */
export const deprecatedFlexLayoutCss = css`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: -1rem;
	margin-right: -1rem;
`;
