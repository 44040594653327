import React from 'react';
import classnames from 'classnames';

export const ColumnFull = ({
	children,
	className = undefined,
	style = null,
}) => {
	if (children == null) return null;

	return (
		<div style={style} className={classnames('column column--full', className)}>
			{children}
		</div>
	);
};
