import React from 'react';

import { Image } from './image';
import { ImageProps } from './image.types';

export const PromoImage = ({
	alt = '',
	...props
}: ImageProps): React.ReactElement | null => (
	<Image
		{...props}
		alt={alt}
		lazyLoad
		imageSize={{
			viewport: 'all',
			verticalOrigin: 'top',
			width: 448,
			height: 252,
			name: 'promo',
		}}
		css={`
			aspect-ratio: 16/9;
		`}
	/>
);
