export const getBlockTypeColorName = (type) => {
	switch (type) {
		case 'wetten-en-regels':
			return 'green';
		case 'direct-regelen':
			return 'purple';
		case 'webinar':
			return 'purple';
		case 'video':
			return 'violet';
		case 'forum':
			return 'violet';
		case 'situaties':
			return 'blue';
		case 'feiten-en-cijfers':
			return 'blue';
		case 'apps':
			return 'blue';
		case 'verhalen':
			return 'blue';
		default:
			return 'blue';
	}
};
