import { RelatedSubjectsBanner } from './ui/RelatedSubjectsBanner';
import { WasteGuidePageData } from './wasteGuide.types';
import { WasteGuideFooterContact } from './WasteGuideFooterContact';

export const WasteGuideFooter = ({
	relatedSubjects,
	contact,
}: {
	relatedSubjects: WasteGuidePageData['relatedSubjects'];
	contact: WasteGuidePageData['contact'];
}) => {
	return (
		<footer
			css={`
				margin-block: 2rem;
			`}
		>
			{relatedSubjects != null && (
				<RelatedSubjectsBanner {...relatedSubjects} />
			)}

			{contact != null && <WasteGuideFooterContact contact={contact} />}
		</footer>
	);
};
