import { config } from 'moduleAlias/config';
import { sanitizeUrl } from '@dop/shared/helpers/link';
import { isBloomreachCDN } from '@dop/ui-composites/media/imageSrcSet';

import { ImageSize } from './image.types';

/**
 * Put the imageSize name inside the image URL (if it is a valid image-variant)
 * so we get the right image variant from bloomreach.
 * @example rewriteBloomreachUrl('.../site/binaries/content/gallery/...', {name: 'menu'}) // => .../site/binaries/menu/content/gallery/...
 */
const rewriteBloomreachUrl = (src: string, imageSize: ImageSize | null) => {
	const { imageVariants } = config;

	if (!imageSize?.name || !imageVariants?.includes(imageSize.name)) {
		return src;
	}

	return src.replace(/(binaries)\/(content)/, `$1/${imageSize.name}/$2`);
};

export const getSrc = (src: string, imageSize: ImageSize | null): string =>
	isBloomreachCDN(src)
		? sanitizeUrl(rewriteBloomreachUrl(src, imageSize))
		: src;
