import styled from 'styled-components';

import { ColumnFull } from 'moduleAlias/project/src/components/layout/columnFull';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { LayoutGridContainer } from '@dop/shared/components/layout/layoutGridContainer';
import { ContactBlockPhone } from '@dop/shared/components/contactBlock/ContactBlockPhone';
import { mq, medium } from '@dop/shared/styleHelpers/mediaQueries';
import { ContactBlockLink } from '@dop/shared/components/contactBlock/ContactBlockLink';
import { WasteGuidePageData } from './wasteGuide.types';

const ContactOptionsContainer = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	${mq({ to: medium })`
        flex-direction: column;
        justify-content: flex-start;
	`}
`;

const ContactItem = styled.article`
	display: flex;
	justify-content: center;
	margin: 0 6rem;
	${mq({ to: medium })`
        margin:  0
	`}
`;

export const WasteGuideFooterContact = ({
	contact,
}: {
	contact: NonNullable<WasteGuidePageData['contact']>;
}) => {
	const { title, introText, organisation, phoneNumbers, links } = contact;

	return (
		<LayoutGridContainer>
			{Boolean(organisation) && (
				<ColumnFull
					css={`
						padding-top: 1.5rem;
						text-align: center;
					`}
				>
					<Heading
						level={2}
						styleLevel={2}
						css={`
							margin-top: 0;
							color: black;
						`}
					>
						{title}
					</Heading>
					<p
						css={`
							margin-bottom: 0.5rem;
						`}
					>
						<span>{introText} </span>
						<span
							css={`
								font-weight: bold;
							`}
						>
							{organisation}
						</span>
					</p>
				</ColumnFull>
			)}
			<ContactOptionsContainer>
				{phoneNumbers?.map((phoneNumber, index) => {
					return (
						<ContactItem key={index}>
							<ContactBlockPhone {...phoneNumber} />
						</ContactItem>
					);
				})}
				{links?.map((link, index) => {
					return (
						<ContactItem key={index}>
							<ContactBlockLink {...link} />
						</ContactItem>
					);
				})}
			</ContactOptionsContainer>
		</LayoutGridContainer>
	);
};
