'use client';
import { ReactNode } from 'react';

import { WasteGuide } from '@dop/pages/wasteGuide/wasteGuide';
import { WasteGuideFooter } from '@dop/pages/wasteGuide/WasteGuideFooter';
import { RelatedSubjectsBanner } from '@dop/pages/wasteGuide/ui/RelatedSubjectsBanner';
import { Main } from '@dop/shared/components/layout/main';
import { Heading } from '@dop/ui-composites/typography/Heading';
import { ArticleMetaData } from '@dop/shared/components/pageSections/headerIntro/ArticleMetaData';
import { BreadcrumbsInDesignSystemWrapper } from '@dop/shared/header/Breadcrumbs';
import { RelatedContent } from '@dop/shared/components/pageSections/relatedContent/relatedContent';
import { WasteGuidePageData } from '@dop/pages/wasteGuide/wasteGuide.types';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { pageGrid } from '@/uiPrimitives/layout/pageGrid/pageGrid';
import { box } from '@/uiPrimitives/layout/box';
import { stack } from '@/uiPrimitives/layout/stack';
import { pageGridItem } from '@/uiPrimitives/layout/pageGrid/pageGridItem';

const WasteGuidePage = ({
	feedbackSlot,
	pageData,
}: {
	feedbackSlot: ReactNode;
	pageData: WasteGuidePageData;
}): React.ReactElement | null => {
	return (
		<>
			<Main $padding={0}>
				{pageData.relatedSubjects != null && (
					<RelatedSubjectsBanner {...pageData.relatedSubjects} />
				)}
				<BreadcrumbsInDesignSystemWrapper />
				<div className={classes(pageGrid({ columnCount: '10-of-12' }))}>
					<div
						className={classes(
							pageGridItem({}),
							box({ paddingBlockStart: '8 | brandBarSize' }),
							stack({})
						)}
					>
						<header
							className={classes(
								stack({ rowGap: '6 | betweenH4Sections' }),
								box({ paddingBlockEnd: '4 | betweenListItems' })
							)}
						>
							<Heading
								level={1}
								$color={['text', 'secondary']}
								$textAlign={'center'}
							>
								{pageData.title}
							</Heading>
							<ArticleMetaData $justifyContentOwners="center" />
						</header>
						<WasteGuide pageData={pageData} />
					</div>
				</div>
				{feedbackSlot}
				<WasteGuideFooter
					relatedSubjects={pageData.relatedSubjects}
					contact={pageData.contact}
				/>
			</Main>
			<RelatedContent />
		</>
	);
};

export default WasteGuidePage;
