import React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

import { without } from '@dop/shared/helpers/functional';
import { isInternalLink } from '@dop/shared/helpers/link';
import { getMediaQueryString } from '@dop/shared/styleHelpers/mediaQueries';
import { neutral } from '@dop/ui-primitives/color/colors';
import { isBloomreachCDN } from '@dop/ui-composites/media/imageSrcSet';

import { getSrc } from './image.helpers';
import {
	ImageProps,
	ImagePropsWithImageSizesArr,
	StyledImgProps,
} from './image.types';

// This styling is redundant for Tridion-images, the origin is used by the webapi to format the image
// BRX does not have these options, so styling will have take care of it to simulate the webapi-behaviour;
const StyledImg = styled.img<StyledImgProps>`
	object-fit: cover;
	object-position: ${({ horizontalOrigin, verticalOrigin }) =>
		`${horizontalOrigin ? horizontalOrigin : '50%'} ${
			verticalOrigin ? verticalOrigin : '50%'
		}`};
`;

// for lazy-loading images, get grey block to display during loading
const lazyLoadPlaceholderCss = css`
	display: block;
	background-color: ${neutral[110]};
`;

const InternalOrBloomreachImage = (props: ImagePropsWithImageSizesArr) => {
	const { className, src, imageSizesArr, lazyLoad } = props;
	const componentProps = without(props, 'lazyLoad', 'imageSizesArr');
	const hasImageSizes = imageSizesArr?.length > 0;

	// plain image is used when no imageSizes are defined, or as a fallback in the <picture> element
	const plainImage = (
		<StyledImg
			{...componentProps}
			css={lazyLoad ? lazyLoadPlaceholderCss : ''}
			src={getSrc(src, hasImageSizes ? imageSizesArr[0] : null)}
			loading={lazyLoad ? 'lazy' : undefined}
			className={className}
		/>
	);

	return hasImageSizes ? (
		<picture>
			{imageSizesArr.map((item, key) =>
				item?.viewport ? (
					<source
						key={key}
						srcSet={getSrc(src, item)}
						media={getMediaQueryString(item.viewport)}
						// ps: any lazy-loading only needs to be defined on the image element
					/>
				) : null
			)}
			{plainImage}
		</picture>
	) : (
		plainImage
	);
};

const ExternalImage = (props: ImagePropsWithImageSizesArr) => {
	const { className, lazyLoad } = props;

	return (
		<StyledImg
			{...without(props, 'imageSizesArr', 'lazyLoad')}
			loading={lazyLoad ? 'lazy' : undefined}
			className={classnames('externalImage', className)}
		/>
	);
};

/**  @deprecated Use new <Image> component in ui-composites instead */
export const Image = (props: ImageProps): React.ReactElement | null => {
	const { src, imageSize, ...restProps } = props;

	if (!src) return null;

	const imageProps = {
		...restProps,
		src,
		// make sure our imageSize(s) is always an array:
		imageSizesArr: [imageSize || []].flat(),
	};

	const internal = isInternalLink(src);
	return internal || isBloomreachCDN(src) ? (
		<InternalOrBloomreachImage {...imageProps} />
	) : (
		<ExternalImage {...imageProps} />
	);
};
