import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';

import { Rows } from '@dop/ui-primitives/layout/Rows';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { LinkBlock } from '@dop/ui-composites/link/LinkBlock';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';

import { getJSIn } from '../helpers/immutableHelpers';
import { MainRowLayout } from '../components/pageLayout/MainRowLayout';
import { DesignSystemWrapper } from '../components/designSystemWrapper/designSystemWrapper';
import { addTestSelector } from '../helpers/testSelector';
import { LinkType } from '@/globals/webApiTypes/common.types';

export const useBreadcrumbs = () => {
	const breadcrumbs = usePageDataSelector(
		getJSIn(['breadcrumbs', 'linkList'])
	) as Array<LinkType> | undefined;

	return breadcrumbs;
};
export const Breadcrumbs = () => {
	const breadcrumbs = useBreadcrumbs();

	if (breadcrumbs == null) return null;

	return (
		<MainRowLayout
			mainSlot={
				<Rows $gap={1}>
					{breadcrumbs.map(({ href, text }, index) => (
						<Box key={index}>
							<Shelf $gap={1}>
								<LinkBlock
									href={href}
									linkType="breadcrumb"
									{...addTestSelector(`uiBreadcrumbLink`)}
								>
									<TextBlock $hover={{ $textDecorationLine: 'underline' }}>
										{text}
									</TextBlock>
								</LinkBlock>
								<Svg
									as={IconChevronRight}
									$blockSize={'var(--cap-height)'}
									$inlineSize={'auto'}
								/>
							</Shelf>
						</Box>
					))}
				</Rows>
			}
		/>
	);
};

export const BreadcrumbsInDesignSystemWrapper = () => {
	const breadcrumbs = useBreadcrumbs();

	if (breadcrumbs == null) return null;

	return (
		<DesignSystemWrapper $padding={[2, 0, 0, 0]}>
			<Breadcrumbs />
		</DesignSystemWrapper>
	);
};
